import React from 'react';
import styled from 'styled-components'




const UnaImage = styled.div.attrs({
  className: "card-img-top"
})

const Image = () => {    

    return (            
              <UnaImage/>            
            )

}

export default Image;