import React from 'react';
import styled from 'styled-components'
import Card from '../components/Card'



const Experiencia = () => {    

    return (
            <Card title="La fiesta de cumpleaños" body="Es el cumple de María y se invitaron a 5 amiguitos a la fiesta. En cuántas partes debería cortar el pastel?"/>
              
            
            )

}

export default Experiencia;
